import {
  AuthorizeModalTextViewState,
  AuthorizeModalTextAction,
  AuthorizeModalTextActionType,
  AuthorizeModalTextStep,
} from '../../../types';

/**
 * The starting state, all texts are hidden in this state.
 */
export const DEFAULT_STATE: AuthorizeModalTextViewState = {
  step: AuthorizeModalTextStep.Start,
  configurable: false,
  headerVisible: false,
  headerDividerVisible: false,
  headerTemplate: '',
  descriptionTemplate: '',
  userNameKnown: false,
  sessionDetected: false,
  templateVariables: {
    clientName: 'the store',
    store: 'the store',
  },
};

/**
 * The default reducer for authorization flows requiring a SMS verification, is also
 * used as the basis for email auth and classic customer accounts.
 * @param {AuthorizeModalTextAction} action - The action to process
 * @param {AuthorizeModalTextViewState} state - The current state of the text view
 * @returns {AuthorizeModalTextViewState} - The updated state of the text view
 */
export function defaultPhoneAuthReducer(
  action: AuthorizeModalTextAction,
  state: AuthorizeModalTextViewState,
): AuthorizeModalTextViewState {
  switch (action.type) {
    case AuthorizeModalTextActionType.Init:
      if (action.payload.personalizeConsentChallenge) {
        return {
          ...state,
          step: AuthorizeModalTextStep.PersonalizeConsent,
          headerVisible: true,
          headerDividerVisible: false,
          userNameKnown: action.payload.userNameKnown || false,
          templateVariables: {
            ...state.templateVariables,
            ...action.payload,
            store: action.payload.clientName,
          },
          headerTemplate: 'login_with_shop.auth_modal.login_title_with_store',
          descriptionTemplate: '',
          sessionDetected: action.payload.userFound,
        };
      }

      return {
        ...state,
        configurable: true,
        headerVisible: true,
        headerDividerVisible: action.payload.userFound,
        step: action.payload.userFound
          ? AuthorizeModalTextStep.OneClick
          : AuthorizeModalTextStep.Start,
        userNameKnown: action.payload.userNameKnown || false,
        templateVariables: {
          ...state.templateVariables,
          ...action.payload,
          store: action.payload.clientName,
        },
        headerTemplate: 'login_with_shop.auth_modal.login_title',
        descriptionTemplate: 'login_with_shop.auth_modal.login_description',
        sessionDetected: action.payload.userFound,
      };
    case AuthorizeModalTextActionType.PopUpOpened:
      return {
        ...state,
        step: AuthorizeModalTextStep.PopUpOpened,
      };
    case AuthorizeModalTextActionType.Restart:
      return {
        ...state,
        configurable: true,
        headerDividerVisible: false,
        step: AuthorizeModalTextStep.Start,
        userNameKnown: false,
        headerTemplate: 'login_with_shop.auth_modal.login_title',
        descriptionTemplate: 'login_with_shop.auth_modal.login_description',
        sessionDetected: false,
        templateVariables: {
          ...state.templateVariables,
          email: '',
          phoneNumber: '',
          userFound: false,
          userNameKnown: false,
        },
      };
    case AuthorizeModalTextActionType.UserMatched:
      if (action.payload.personalizeConsentChallenge) {
        return {
          ...state,
          step: AuthorizeModalTextStep.PersonalizeConsent,
          userNameKnown: action.payload.hasName || false,
          headerVisible: true,
          headerDividerVisible: false,
          headerTemplate: 'login_with_shop.auth_modal.login_title_with_store',
          descriptionTemplate: '',
          sessionDetected: action.payload.userCookieExists,
        };
      }

      if (action.payload.userCookieExists) {
        return {
          ...state,
          configurable: true,
          headerDividerVisible: true,
          step: AuthorizeModalTextStep.OneClick,
          userNameKnown: action.payload.hasName || false,
          sessionDetected: true,
        };
      }

      return {
        ...state,
        userNameKnown: action.payload.hasName || false,
        sessionDetected: false,
      };
    case AuthorizeModalTextActionType.UserNotMatched:
      return {
        ...state,
        step: AuthorizeModalTextStep.SignUp,
        headerDividerVisible: true,
        userNameKnown: false,
        configurable: false,
        headerTemplate: 'login_with_shop.auth_modal.signup_title',
        descriptionTemplate: 'login_with_shop.auth_modal.signup_description',
        sessionDetected: false,
      };
    case AuthorizeModalTextActionType.VerificationStepChanged: {
      const {step, phone = '', email = ''} = action.payload;
      return {
        ...state,
        step: verificationStepToModalStep(step),
        configurable: false,
        headerDividerVisible: true,
        templateVariables: {
          ...state.templateVariables,
          phoneNumber: phone.replaceAll(' ', '\xa0'),
          email,
        },
        legalTextVariant: 'authorize',
        ...(step === 'email' && {
          headerTemplate: 'login_with_shop.auth_modal.login_email_title',
          descriptionTemplate:
            'login_with_shop.auth_modal.login_email_description',
        }),
        ...(step === 'sms' && {
          headerTemplate: 'login_with_shop.auth_modal.login_sms_title',
          descriptionTemplate:
            'login_with_shop.auth_modal.login_sms_description',
        }),
        ...(step === 'one_click' && {
          headerTemplate: 'login_with_shop.auth_modal.login_title',
          descriptionTemplate: 'login_with_shop.auth_modal.login_description',
        }),
        ...(step === 'webauthn' && {
          headerTemplate: 'login_with_shop.auth_modal.login_webauthn_title',
          descriptionTemplate:
            'login_with_shop.auth_modal.login_webauthn_description',
          legalTextVariant: 'generic',
          legalTextTemplate: 'login_with_shop.auth_modal.login_webauthn_footer',
          headerDividerVisible: false,
        }),
      };
    }
  }

  return state;
}

/**
 * The default reducer for authorization flows requiring an email verification
 * @param {AuthorizeModalTextAction} action - The action to process
 * @param {AuthorizeModalTextViewState} state - The current state of the text view
 * @returns {AuthorizeModalTextViewState} - The updated state of the text view
 */
export function defaultEmailAuthReducer(
  action: AuthorizeModalTextAction,
  state: AuthorizeModalTextViewState,
): AuthorizeModalTextViewState {
  const newState = defaultPhoneAuthReducer(action, state);

  switch (newState.step) {
    case AuthorizeModalTextStep.Start:
      return {
        ...newState,
        headerTemplate: 'verified_email_auth.auth_modal.login_title',
        descriptionTemplate: '',
      };
    case AuthorizeModalTextStep.SignUp:
      return {
        ...newState,
        headerTemplate: 'verified_email_auth.auth_modal.signup_title',
        descriptionTemplate:
          'verified_email_auth.auth_modal.signup_description',
      };
    case AuthorizeModalTextStep.PhoneVerification:
      return {
        ...newState,
        headerTemplate: 'login_with_shop.auth_modal.login_sms_title',
        descriptionTemplate: 'login_with_shop.auth_modal.login_sms_description',
      };
    case AuthorizeModalTextStep.EmailVerification:
      return {
        ...newState,
        headerTemplate: 'login_with_shop.auth_modal.login_email_title',
        descriptionTemplate:
          'login_with_shop.auth_modal.login_email_description',
      };
  }

  return newState;
}

/**
 * The reducer for sign up with Shop on classic customer accounts
 * @param {AuthorizeModalTextAction} action - The action to process
 * @param {AuthorizeModalTextViewState} state - The current state of the text view
 * @returns {AuthorizeModalTextViewState} - The updated state of the text view
 */
export function classicCustomerAccountsSignUpReducer(
  action: AuthorizeModalTextAction,
  state: AuthorizeModalTextViewState,
): AuthorizeModalTextViewState {
  const newState = defaultPhoneAuthReducer(action, state);

  switch (newState.step) {
    case AuthorizeModalTextStep.Start:
    case AuthorizeModalTextStep.OneClick:
      return {
        ...newState,
        headerTemplate: 'customer_accounts.sign_up_page.auth_modal.login_title',
        descriptionTemplate:
          'customer_accounts.sign_up_page.auth_modal.login_description',
      };
    case AuthorizeModalTextStep.PhoneVerification:
      return {
        ...newState,
        headerTemplate: 'customer_accounts.sign_up_page.auth_modal.login_title',
        descriptionTemplate:
          'customer_accounts.sign_up_page.auth_modal.login_sms_description',
      };
    case AuthorizeModalTextStep.EmailVerification:
      return {
        ...newState,
        headerTemplate: 'customer_accounts.sign_up_page.auth_modal.login_title',
        descriptionTemplate:
          'customer_accounts.sign_up_page.auth_modal.login_email_description',
      };
    case AuthorizeModalTextStep.WebAuthnVerification:
      return {
        ...newState,
        headerTemplate: 'customer_accounts.sign_up_page.auth_modal.login_title',
        descriptionTemplate:
          // temporary, a separate PR will rectify this
          'login_with_shop.auth_modal.login_webauthn_description',
      };
  }

  return newState;
}

/**
 * The reducer for the Guest Checkout Verification Modal
 * @param {AuthorizeModalTextAction} action - The action to process
 * @param {AuthorizeModalTextViewState} state - The current state of the text view
 * @returns {AuthorizeModalTextViewState} - The updated state of the text view
 */
export function checkoutModalReducer(
  action: AuthorizeModalTextAction,
  state: AuthorizeModalTextViewState,
): AuthorizeModalTextViewState {
  const newState = defaultPhoneAuthReducer(action, state);

  switch (newState.step) {
    case AuthorizeModalTextStep.PhoneVerification:
      return {
        ...newState,
        descriptionTemplate: 'checkout_modal.auth_modal.login_sms_description',
      };
    case AuthorizeModalTextStep.EmailVerification:
      return {
        ...newState,
        descriptionTemplate:
          'checkout_modal.auth_modal.login_email_description',
      };
  }

  return newState;
}

/**
 * The reducer for the payment request flow for Shop Pay Commerce Component
 * @param {AuthorizeModalTextAction} action - The action to process
 * @param {AuthorizeModalTextViewState} state - The current state of the text view
 * @returns {AuthorizeModalTextViewState} - The updated state of the text view
 */
export function paymentRequestReducer(
  action: AuthorizeModalTextAction,
  state: AuthorizeModalTextViewState,
): AuthorizeModalTextViewState {
  const newState = defaultPhoneAuthReducer(action, state);

  switch (newState.step) {
    case AuthorizeModalTextStep.PopUpOpened:
    case AuthorizeModalTextStep.OneClick:
      return {
        ...newState,
        headerTemplate: 'payment_request.auth_modal.login_title',
        descriptionTemplate: 'payment_request.auth_modal.login_description',
      };
    case AuthorizeModalTextStep.PhoneVerification:
      return {
        ...newState,
        headerTemplate: 'payment_request.auth_modal.login_sms_title',
        descriptionTemplate: 'payment_request.auth_modal.login_sms_description',
      };
    case AuthorizeModalTextStep.EmailVerification:
      return {
        ...newState,
        headerTemplate: 'payment_request.auth_modal.login_email_title',
        descriptionTemplate:
          'payment_request.auth_modal.login_email_description',
      };
  }

  return newState;
}

/**
 * Converts step received from a verification step event, to a step to be rendered in the modal.
 * @param {string} step The step received from the verification step event.
 * @returns {AuthorizeModalTextStep} The step to be rendered in the modal.
 */
function verificationStepToModalStep(step: string): AuthorizeModalTextStep {
  switch (step) {
    case 'one_click':
      return AuthorizeModalTextStep.OneClick;
    case 'email':
      return AuthorizeModalTextStep.EmailVerification;
    case 'webauthn':
      return AuthorizeModalTextStep.WebAuthnVerification;
    default:
    case 'sms':
      return AuthorizeModalTextStep.PhoneVerification;
  }
}

import {MonorailTracker as MonorailTrackerBase} from '../../../common/analytics';
import {
  LoginWithShopSdkPageName,
  LoginWithShopSdkUserAction,
} from '../../../common/analytics/types';
import {SHOP_FOLLOW_FLOW} from '../../../constants/followButton';

export default class FollowOnShopMonorailTracker extends MonorailTrackerBase {
  /**
   * @param {object} params The constructor params.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   */
  constructor({
    elementName,
    analyticsTraceId,
  }: {
    elementName: string;
    analyticsTraceId?: string;
  }) {
    super({elementName, analyticsTraceId, flow: SHOP_FOLLOW_FLOW});
  }

  /**
   * Fired when the Follow on Shop button is shown.
   * @param {boolean} isFollowing Whether the user is following the store already.
   * with different implementations and potentially different performance metrics.
   */
  trackFollowButtonPageImpression(isFollowing: boolean) {
    this.trackPageImpression({
      page: isFollowing
        ? LoginWithShopSdkPageName.ComponentLoadedFollowing
        : LoginWithShopSdkPageName.ComponentLoadedNotFollowing,
    });
  }

  /**
   * Fired when the Follow on Shop button is visible in the viewport
   *
   */
  trackFollowButtonInViewport() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.FollowButtonShownInViewport,
    });
  }

  /**
   * Fired when the secondary modal with the Get Shop App button is shown.
   *
   */
  trackFollowingGetAppButtonPageImpression() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.FollowingGetShopAppCta,
    });
  }

  /**
   * Fired when the shop-follow-button is clicked.
   *
   */
  trackFollowButtonClicked() {
    this.trackShopPayLoginWithShopSdkUserAction({
      userAction: LoginWithShopSdkUserAction.FollowOnShopClicked,
    });
  }

  /**
   * Fired when the Get Shop App button is clicked.
   *
   */
  trackFollowingGetAppButtonClicked() {
    this.trackShopPayLoginWithShopSdkUserAction({
      userAction: LoginWithShopSdkUserAction.FollowingGetShopAppClick,
    });
  }
}

import {
  LoginWithShopSdkPageName,
  LoginWithShopSdkUserAction,
} from '../../../common/analytics/types';
import {ClassicCustomerAccountsFlowVersion} from '../../../types';
import {DefaultComponentAnalyticsContext as AnalyticsContext} from '../../../constants/loginDefault';

import DefaultComponentMonorailTracker from './DefaultComponentMonorailTracker';

export default class ClassicCustomerAccountsMonorailTracker extends DefaultComponentMonorailTracker {
  /**
   * @param {object} params The constructor params.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. 'sign_in' or 'sign_up').
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   */
  constructor({
    elementName,
    flowVersion,
    analyticsTraceId,
  }: {
    elementName: string;
    flowVersion: ClassicCustomerAccountsFlowVersion;
    analyticsTraceId?: string;
  }) {
    super({
      elementName,
      analyticsTraceId,
      analyticsContext: AnalyticsContext.ClassicCustomerAccounts,
      flowVersion,
    });
  }

  /**
   * Fired when the SDK is loaded in the Classic Customer Accounts login page.
   */
  trackClassicCustomerAccountsLoginPageImpression() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.ClassicCustomerAccountLogin,
    });
  }

  /**
   * Fired when the SDK is loaded in the Classic Customer Accounts create account page.
   */
  trackClassicCustomerAccountsCreateAccountPageImpression() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.ClassicCustomerAccountCreateAccount,
    });
  }

  trackClassicCustomerAccountsAccountPageImpression() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.ClassicCustomerAccount,
    });
  }

  trackClassicCustomerAccountsContinueWithShopPageImpression() {
    this.trackPageImpression({
      page: LoginWithShopSdkPageName.ContinueWithShop,
    });
  }

  trackClassicCustomerAccountsCreateAccountAction() {
    this.trackShopPayLoginWithShopSdkUserAction({
      userAction: LoginWithShopSdkUserAction.CreateAccountLinkClicked,
    });
  }
}
